import React from 'react';
import Slider from 'react-slick';
import Footer from '../components/footer';
import 'bootstrap';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.invocaClick = this.invocaClick.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    const summary = data.markdownRemark.frontmatter.intro_options.summary_text;
    var acc = document.querySelectorAll(".accordion");
    var arr = Array.prototype.slice.call(acc);
    var panels = document.querySelectorAll('.panel');
    arr.forEach( function(item, index) {
      item.addEventListener("click", function() {
        var panel = panels[index];
        if (panel.style.display === "block") {
          panel.style.display = "none";
          item.innerHTML = summary + ' +';
        } else {
          panel.style.display = "block";
          item.innerHTML = summary + ' -';
        }
      });
    });
  }

  invocaClick(product) {
    if (product.invoca) {
      return (
        <span className={`ppc_integration ${product.invoca} float-right ml-2 mt-2`}>
          <a href="javscript:;" className="ppc_link">
            <span className="ppc_number">855-688-0773</span>
          </a>
        </span>
      );
    }
  }

  render() {
    
    const settings = {
      arrows: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 376,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    }

    const { data, location } = this.props;
    const product = data.markdownRemark.frontmatter;
    return (
      <div className='container-fluid p-0'>

        <Seo data={data} location={location} />
        
        <header className='row pt-3 pb-3'>
            <div className="container col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <a href="https://payrollpro.co"><h2 className="float-left"><strong>PayrollPro</strong></h2></a>
              {this.invocaClick(product)}

              <div className="float-right">
              <a href={product.button_url}><button className="nav-link btn btn-lg btn-rounded" style={{backgroundColor: product.button_color, color: product.btn_txt_color }}>{product.intro_options.cta}</button></a>
              </div>
            </div>
        </header>

        
        <div className='container-fluid introduction pt-5 pb-5 pl-0 pr-0' style={{backgroundColor: product.background_color}}>
          <div className='container section'>
            <div className="row ">
              <div className="col-md-6">
                <div className="row pb-3">
                  <div className="container col-md-3 col-lg-2">
                    <img className='img-fluid' src={product.intro_options.product_logo} alt="" />
                  </div>
                  <div className="container col-md-9 col-lg-10">
                    <h1 className='mt-2'>{product.title}</h1>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: product.intro_options.product_details}}></div>
<a href={product.button_url}><button className="btn-lg btn-rounded pl-5 pr-5 pt-2 pb-2" style={{fontSize: '25px', backgroundColor: product.button_color, color: product.btn_txt_color }}>{product.intro_options.cta}</button></a>
                <div className="stars mt-3">
                  <img className='img-fluid' src={`/images/${product.intro_options.star_rating}.png`} alt='' />			
                </div>
                <div className="row mt-4">
                  <a className="accordion col-12 text-left" href="javscript:;">{product.intro_options.summary_text} +</a>
                  <div className="panel mt-4">
                    <p dangerouslySetInnerHTML={{ __html: product.intro_options.disclaimer}}></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <img className='img-fluid' src={product.intro_options.featured_image} alt="" height="" />
              </div>
            </div>
          </div>
        </div>


        <div className='row features mt-5 mb-5'>
          <div className="container section col-xs-12 col-sm-12 col-md-8 col-lg-6">
            <div className='container-fluid text-center'>
              <h2>{product.feature_options.features_title}</h2>		
              <h3>{product.feature_options.features_subtitle}</h3>
            </div>
            <div className='col-12 mt-5'>
                <Slider {...settings}>
                  {product.feature_options.features.map((item, index) => {
                    return (
                        <div key={index}>
                          <img key={index} className='col-12 mb-3 img-fluid' src={item.product_screenshot} alt="" />
                          <h4 className='col-12'>{item.feature_title}</h4>
                          <p className='col-12'>{item.feature_detail}</p>
                        </div>
                    );
                  })}
                </Slider>
            </div>
          </div>
        </div>


        <div className='row reviews mt-5 mb-5'>
          <div className="container section col-xs-12 col-sm-12 col-md-8 col-lg-6">
            <div className='row mt-5 mb-5'>
              <h2 className="col-12 text-center">Reviews</h2>		
            </div>
            <div className="col-12">
              <Slider {...settings}>
                {product.reviews.reviews.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item.product_logo} alt="" height={'70px'} width={'70px'} />
                      <p>{item.quote}</p>
                      <p>Source: <a href={item.source_url}>{item.review_source}</a></p>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>

        <div className="row callout mt-5 mb-5 pt-5 pb-5" style={{backgroundColor: product.background_color}}>
          <div className='container section'>
            <div className="row text-center">
              <div className="col-xs-12 col-sm-6">
                <h4>{product.intro_options.cta}</h4>
              </div>
              <div className="stars col-xs-12 col-sm-6">
                <img className='img-fluid ' src={`/images/${product.intro_options.star_rating}.png`} alt='' />	
              </div>
            </div>
          </div>
        </div>

        <div className="row more-info mt-5 mb-5">
          <div className="container section col-xs-12 col-sm-12 col-md-8 col-lg-6" >
            { product.product_description &&  
              product.product_description.map((item,index) => {
              return (
                <div key={index}>
                  <div className='container ' dangerouslySetInnerHTML={ {__html: item.description} }></div>
                  <div className='container embed-responsive embed-responsive-16by9 mt-4'>
                    <iframe className='embed-responsive-item' title={item.id} src={`https://www.youtube.com/embed/${item.id}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='container'><hr></hr></div>

        <div className="row additional-features mt-5 mb-5">
          <div className="container section justify-content-center">
            <h2 className="text-center mb-5">{product.more_product_features.more_features_title}</h2>
            <h5 className="text-center mb-5">{product.more_product_features.more_features_subtitle}</h5>				
            <div className="row">
              {product.more_product_features.more_features.map((item, index) => {
                return (
                  <div className="col-md-4 mt-5 justify-content-center" key={index}>
                    <div className="feature-icon text-center mb-3">
                      <i className={`fas fa-${item.icon}`} style={{ fontSize: '100px', color: '#007bff' }}></i>
                    </div>
                    <h4 className='text-center'>{item.highlight}</h4>
                  </div>
                );
              })}
            </div>
          </div> 
        </div>

        <div className='row end-callout justify-content-center pt-5 pb-5 mt-5 mb-5' style={{backgroundColor: product.background_color}}>
          <div className="container-fluid text-center section " style={{backgroundColor: product.background_color}}>
            <a className='' href={product.button_url}><button className="btn btn-lg btn-rounded" style={{ fontSize: '25px', backgroundColor: product.button_color, color: product.btn_txt_color }}>{product.intro_options.cta}</button></a>
            <div className="col-12 text-center">
              <img src={`/images/${product.intro_options.star_rating}.png`} alt='' />	
            </div>
              
            <div className="row mt-4">
              <a className="accordion col-12 text-center" href="javscript:;">{product.intro_options.summary_text} +</a>
              <div className="panel section mt-4">
                <p dangerouslySetInnerHTML={{ __html: product.intro_options.disclaimer}}></p>
              </div>
            </div>
          </div>
        </div>

        <div className="row additional-products mt-5 mb-5">
          <div className="container section justify-content-center">
            <h2 className="text-center mb-5">{product.read_more.read_more_title}</h2>				
            <div className="row">
              {product.read_more.products.map((item, index) => {
                return (
                  <a className="blog-item d-flex col-md-4" key={index} href={item.url}> 
                    <div className="card mt-3 blog-posts">
                      <img className="img-fluid" src={item.product_logo} alt='' width={350} />
                      <div className="card-body">
                        <div className="blog-details">
                          <h5 className="blog-title card-text pt-3">{item.title}</h5>
                          <p className="blog-detail card-text">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div> 
        </div>
        <hr></hr>
        <Footer />
        <script src="//js5.invoca.net/5/publisher_integration.js"></script>
        <script>Invoca.affiliate_integration( );</script>
      </div>
    );
  }
}

export default LandingPage;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        template
        title
        type
        red_track
        background_color
        button_color
        button_url
        btn_txt_color
        invoca
        intro_options {
          cta
          star_rating
          product_logo
          featured_image
          disclaimer
          product_details
          summary_text
        }
        feature_options {
          features_title
          features_subtitle
          features {
            product_screenshot
            feature_title
            feature_detail
          }
        }
        reviews {
          reviews_title
          reviews {
            product_logo
            quote
            review_source
            source_url
          }
        }
        product_description {
          id
          description
        }
        more_product_features {
          more_features_title
          more_features_subtitle
          more_features {
            icon
            highlight
          }
        }
        read_more {
          read_more_title
          products {
            product_logo
            title
            description
            url
          }
        }
      }
    }
  }
`;
